import background from './assets/rhys-moult-7eaFIKeo1MQ-unsplash.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={background} className="App-background" alt="background" />
      <h1 className="App-title">Muy pronto!</h1>
    </div>
  );
}

export default App;
